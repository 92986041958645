import axios, {AxiosInstance} from "axios";
import {toast} from "react-toastify";
import {ErrorToastSettings} from "../toast/toastSettings";

export const getBaseURL = ()  =>{
    const currentURL = window.location.origin

    if (currentURL.includes('localhost') || currentURL.includes('develop')) {
        return 'https://develop.harmonica.cloud'
    } else {
        return  process.env.REACT_APP_BASE_URL
    }
}

export const token = localStorage.getItem('token');

const apiClient:AxiosInstance = axios.create({
    baseURL: getBaseURL(),
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
    },
});

apiClient.interceptors.request.use((config) => {
    if(!config.headers["Authorization"] && localStorage.getItem('token')){
        config.headers["Authorization"] = localStorage.getItem('token');
        return config;
    }
    return config;
});

apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const isLogout = error?.response?.status === 401;
        const isLogout403 = error?.response?.data?.path === '/api/v1/users/me' && error?.response?.status === 403

        if(error?.response?.status === 403){
            toast.error('Помилка прав доступу. Зверніться до адміністратора за розширенням прав.', ErrorToastSettings);
        }

        if (isLogout || isLogout403) {
            window.location.replace(`${window.location.origin}/login`);
            localStorage.removeItem('token');
        }
        return Promise.reject(error);
    }
);

export default apiClient;
