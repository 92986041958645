import {EnumCallStatus, ILastActivityResource} from "./ICall.resource";

export interface ICallChecklistResourceServer {
    phoneCall: IPhoneCallResource;
    numMetricSum: number;
    numMetricTotal: number;
    [key:string]: IColumnResource | IPhoneCallResource | number;
}

export interface IPhoneCallResource {
    readonly uuid: string;
    readonly companyId: number;
    readonly employee: IPhoneCallChecklistEmployee;
    readonly phoneNumber: string;
    readonly created: string;
    readonly callTime: string;
    readonly channels: number;
    readonly fileName: string;
    readonly blobUrl: string;
    readonly duration: number;
    readonly decodingStatus: string;// ?????????
    readonly status: string; // ????????
    readonly state: EnumCallStatus;
    readonly lastActivity: ILastActivityResource;
    readonly problemResult: number;
    readonly countWithSameNumber: number;
    readonly countWithSameNumberPeriodStart: string;
    readonly countWithSameNumberPeriodEnd: string;
    readonly extId: string;
    readonly internalNumber: string;
}

export interface IPhoneCallChecklistEmployee {
    readonly id: number;
    readonly userId: number;
    readonly companyId: number;
    readonly email: string;
    readonly fullName: string;
    readonly departament: string | null;
}

export interface IColumnResource {
    readonly block: string
    readonly type: string;
    readonly question: string;
    readonly value: number;
}

export interface IColumnChecklistResource {
    columnName: string;
    data: IColumnResource;
}

export interface ICallChecklistResource {
    phoneCall:IPhoneCallResource;
    columns: IColumnChecklistResource[];
    numMetricSum: number;
    numMetricTotal: number;
}

export const mapCallChecklist = (data:ICallChecklistResourceServer[]):ICallChecklistResource[] => {
    return data.map((item:ICallChecklistResourceServer) => {
        let columns = [];

        for (const key in item) {

            if(key !== 'phoneCall' && key !== 'numMetricSum' && key !== 'numMetricTotal'){
                columns.push({
                    columnName: key,
                    data: item[key] as IColumnResource
                })
            }
        }

        return {
            columns: columns,
            numMetricSum: item.numMetricSum,
            numMetricTotal: item.numMetricTotal,
            phoneCall: item.phoneCall
        }
    })

};

