import React from 'react';
import ModalContainer from "../../../../components/ModalContainer";
import AddedCallsAmount from "../AddedCallsAmount";
import UploadCallsModalBtn from "../UploadCallsModalBtn";
import UploadFileItem from "../UploadFileItem";
import TextButton from "../../../../components/common/TextButton";
import Button from "../../../../components/common/Button";
import styles from './styles.module.scss';

interface IProps {
    handleClose: () => void;
    handleDrop: (e:React.DragEvent<HTMLLabelElement>) => void;
    files: File[];
    handleDeleteFile: (file:File) => void;
    handleChangeUploadFile:(e:React.ChangeEvent<HTMLInputElement>) => void;
    handleLoadFiles: () => void;
    isError: boolean;
    isLoading:boolean;
}

const ModalImportCalls = ({handleClose, handleDrop, files, handleDeleteFile, handleChangeUploadFile, handleLoadFiles, isError, isLoading}:IProps) => {

    return (
        <ModalContainer
            handleClose={handleClose}
        >
            <div className={styles.modal}>
                <div className={styles.modal__header}>
                    <h2 className={styles.modal__title}>
                        Import calls
                    </h2>
                    <p className={styles.modal__description}>
                        Зверни увагу, що моно записи дають гіршу якість розпізнавання
                    </p>
                </div>
                <div className={styles.modal__body}>
                    <AddedCallsAmount
                        amount={files.length}
                        maxAmount={10}
                    />
                    {files.length !== 0 ? (
                        <div className={styles.modal__list}>
                            {files.map((item:File) => (
                                <UploadFileItem
                                    key={item.name}
                                    data={item}
                                    handleDeleteFile={handleDeleteFile}
                                />
                            ))}
                        </div>
                    ) : null}
                    <UploadCallsModalBtn
                        handleDrop={handleDrop}
                        handleChangeUploadFile={handleChangeUploadFile}
                    />
                    {isError ? (
                        <p className={styles.modal__error}>
                            Ліміт безкоштовного аналізу розмов - вичерпано.
                        </p>
                    ) : null}
                </div>
                {files.length > 10 ? (
                    <p className={styles.modal__error}>Максильна кількість, яку можна завантажити за один раз 10 файлів</p>
                ) : null}
                <div className={styles.modal__footer}>
                    <div className={styles.modal__btns}>
                        <TextButton
                            text={'Cancel'}
                            onClick={handleClose}
                        />
                        <Button
                            disabled={files.length > 10 || isLoading}
                            text={'Load'}
                            onClick={handleLoadFiles}
                        />
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
};

export default ModalImportCalls;
