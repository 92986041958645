import React from 'react';
import styles from './styles.module.scss';

const SelectedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.2573 7.22745C19.5502 7.52033 19.5503 7.9952 19.2574 8.28811L10.773 16.7734C10.6324 16.9141 10.4416 16.9931 10.2427 16.9931C10.0437 16.9931 9.85297 16.9141 9.71231 16.7735L5.46967 12.5308C5.17678 12.2379 5.17678 11.7631 5.46967 11.4702C5.76256 11.1773 6.23744 11.1773 6.53033 11.4702L10.2426 15.1824L18.1967 7.22751C18.4895 6.9346 18.9644 6.93458 19.2573 7.22745Z" fill="#1E1E1E"/>
        </svg>
    )
};

interface IProps {
    handleSelect?: (e:React.MouseEvent<HTMLButtonElement>) => void;
    isSelected?: boolean;
    label?: string;
    value?: string;
    color?:string;
}

const SelectItem = ({handleSelect, isSelected, value, label, color}:IProps) => {
    return (
        <button
            type={'button'}
            className={`${styles.select__item}`}
            id={value}
            name={label}
            onClick={handleSelect}
        >
            <div className={`${styles.select__label} ${isSelected ? styles.select__item_active : ''}`}>
                {color ? (
                    <div
                        className={styles.select__circle}
                        style={{background: color}}
                    />
                ) : null}
                {label}
            </div>
            {isSelected ? <SelectedIcon/> : null}
        </button>
    );
};

export default SelectItem;
