import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from "dayjs/plugin/utc";
dayjs.extend(relativeTime);
dayjs.extend(utc);

class DateService {
    getListOfDays(amount:number) {
        let today = dayjs(new Date());
        let days = [today.format()];

        let daysAmount = 1;

        for (let i = 0; i < amount; i++) {
            const tomorrow = dayjs(today).add(daysAmount, 'day').format();
            days.push(tomorrow)
            daysAmount+=1;
        }

        return days;
    };

    formatUI(date:string, format:string='DD.MM.YYYY HH:mm:ss'):string {
        return dayjs(new Date(date)).format(format);
    }

    convertSecondsToMinutes(seconds: number):string {
        const minutes = Math.floor((seconds / 60));
        const secondsLeft = (seconds % 60).toFixed(0);
        return `${minutes}:${Number(secondsLeft) < 10 ? `0${secondsLeft}` : secondsLeft} хв`;
    }

    getFromNow(date:string) {
        return dayjs(date).fromNow(false);
    }

    getTodayStartEnd() {
        const currentDate = dayjs().format('YYYY-MM-DD');
        const currentDateEnd = dayjs().add(1, 'days').format('YYYY-MM-DD');

        return {
            start: currentDate,
            end: currentDateEnd
        }
    }

    getYesterdayStartEnd() {
        const yesterday = dayjs().add(-1, 'days').format('YYYY-MM-DD');
        const yesterdayEnd = dayjs().format('YYYY-MM-DD');

        return {
            start: yesterday,
            end: yesterdayEnd
        }
    }

    getCurrentWeekStartEnd(){
        let currentDate = dayjs()
        if(currentDate.day() === 0) {
            currentDate = currentDate.add(-1, 'days');
        }

        if(currentDate.day() === 6) {
            currentDate = currentDate.add(-1, 'days');
        }

        const startWeek = currentDate.startOf('week').add(1, "days").format('YYYY-MM-DD');
        const endWeek = currentDate.endOf('week').add(1, "days").format('YYYY-MM-DD');
        return  {
            start: startWeek,
            end: endWeek
        }
    }

    getCurrentMonthStartEnd(){
        const startMonth = dayjs().startOf("month").format('YYYY-MM-DD');
        const endMonth = dayjs().endOf("month").format('YYYY-MM-DD');

        return  {
            start: startMonth,
            end: endMonth
        }
    }

    getLast30Days(){
        const startMonth = dayjs().subtract(30, 'days').format('YYYY-MM-DD');
        const endMonth = dayjs().format('YYYY-MM-DD');

        return  {
            start: startMonth,
            end: endMonth
        }
    }

    getTime(date:string):number {
        return new Date(date).getTime();
    }

    transformPicker = (date: any) => {
        if(!date) return '';
        const amountHours = Number(dayjs(new Date(date)).format('HH')) * -1;
        return dayjs.utc(date).add(amountHours, 'hour').format('YYYY-MM-DD HH:mm:ss')
    };

}

export default new DateService();
