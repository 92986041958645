import React, { useState} from 'react';
import styles from './styles.module.scss';

interface IProps {
    label: string;
    fullText: string;
}

const Tooltip = ({label, fullText}:IProps) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    const handleMouseEnter = (e:React.MouseEvent) => {
        const rect = e.currentTarget.getBoundingClientRect();
        let x = e.clientX - 360;
        let y = e.clientY + 15;
        setX(x)
        setY(y)
        setShowTooltip(true)
    };
    const handleMouseLeave = () => setShowTooltip(false);
    return (
        <div
            className={styles.tooltip}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                className={styles.tooltip__label}
            >
                {label}
            </div>
            {showTooltip ? (
                <div
                    className={styles.tooltip__tooltip}
                    style={{
                        position: 'fixed',
                        top: y,
                        left: x
                    }}
                >
                    {fullText}
                </div>
            ) : null}
        </div>
    );
};

export default Tooltip;
