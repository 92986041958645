import React from 'react';
import styles from './styles.module.scss';
import DateService from "../../../../services/DateService";

export enum EnumDateFastFilterType {
    TODAY= 'TODAY',
    YESTERDAY = 'YESTERDAY',
    CURRENT_WEEK = 'CURRENT_WEEK',
    CURRENT_MONTH = 'CURRENT_MONTH',
    LAST_30_DAYS = 'LAST_30_DAYS',
    CUSTOM_RANGE = 'CUSTOM_RANGE',
}

interface IProps {
    type: EnumDateFastFilterType;
    amount?: number;
    isActive: boolean;
    handleSetDate: (start:string, end:string, type: EnumDateFastFilterType) => void;
}

const DateFastFilter = ({type, amount, isActive, handleSetDate}:IProps) => {

    const filterNameFactory = (type:EnumDateFastFilterType) => {
        switch (type) {
            case EnumDateFastFilterType.TODAY:
                return 'Сьогодні';
            case EnumDateFastFilterType.YESTERDAY:
                return 'Вчора';
            case EnumDateFastFilterType.LAST_30_DAYS:
                return '30 днів';
            case EnumDateFastFilterType.CURRENT_WEEK:
                return 'Цього тижня';
            case EnumDateFastFilterType.CURRENT_MONTH:
                return 'Цього місяця';
            default:
                return 'Сьогодні'
        }
    }

    const filterStartEndFactory = (type:EnumDateFastFilterType) => {
        switch (type) {
            case EnumDateFastFilterType.TODAY:
                return DateService.getTodayStartEnd();
            case EnumDateFastFilterType.YESTERDAY:
                return DateService.getYesterdayStartEnd();
            case EnumDateFastFilterType.CURRENT_WEEK:
                return DateService.getCurrentWeekStartEnd();
            case EnumDateFastFilterType.CURRENT_MONTH:
                return DateService.getCurrentMonthStartEnd();
            case EnumDateFastFilterType.LAST_30_DAYS:
                return DateService.getLast30Days();
            default:
                return DateService.getTodayStartEnd();
        }
    };
    const handleClick = () => {
        const startEnd = filterStartEndFactory(type);
        handleSetDate(startEnd.start, startEnd.end, type);
    }

    return (
        <button
            className={`${styles.filter} ${isActive ? styles.filter__active : ''}`}
            type={'button'}
            onClick={handleClick}
        >
            <span className={styles.filter__filter}>
                {filterNameFactory(type)}
            </span>
            {amount || amount === 0 ? (
                <span className={styles.filter__amount}>
                    {amount}
                </span>
            ) : null}
        </button>
    );
};

export default DateFastFilter;
