import React from 'react';
import {Route, Routes, BrowserRouter as Router} from "react-router-dom"
import ROUTES from "./routes";
import LoginPage from "./pages/LoginPage";
import PrivateRoute from "./components/PrivateRoute";
import CallDetailsPage from "./pages/CallDetailsPage";
import ProfilePage from "./pages/ProfilePage";
import RegistrationPage from "./pages/RegistrationPage";
import CreateCompanyPage from "./pages/CreateCompanyPage";
import RecoverPasswordPage from "./pages/RecoverPasswordPage";
import GridFactory from "./components/GridFactory";
import ResetPasswordEmailPage from "./pages/ResetPasswordEmailPage";
import ChecklistPage from "./pages/ChecklistPage";

function App() {

  return (
      <Router>
          <Routes>
              <Route element={<PrivateRoute />}>
                  <Route path={ROUTES.HOME} element={<GridFactory />} />
                  <Route path={ROUTES.CHECK_LIST} element={<ChecklistPage />} />
                  <Route path={ROUTES.CALL_DETAILS} element={<CallDetailsPage />} />
                  <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
              </Route>
              <Route path={ROUTES.LOGIN} element={<LoginPage />} />
              <Route path={ROUTES.CREATE_PASSWORD} element={<RecoverPasswordPage />} />
              <Route path={ROUTES.RESET_PASSWORD_EMAIL} element={<ResetPasswordEmailPage />} />
              <Route path={ROUTES.REGISTER} element={<RegistrationPage />} />
              <Route path={ROUTES.CREATE_COMPANY} element={<CreateCompanyPage />} />
          </Routes>
      </Router>
  );
}

export default App;
