import React from 'react';
import Sidebar from "../../components/Sidebar";
import styles from './styles.module.scss';
import UserInfo from "../../components/UserInfo";
import CompanyInfo from "../../components/CompanyInfo";

const TitleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="12" viewBox="0 0 120 12" fill="none">
            <path d="M109.962 11.4047L114.147 0.594727H115.846L120 11.4047H118.224L115.753 4.88782C115.723 4.81575 115.661 4.64073 115.568 4.36276C115.486 4.08479 115.388 3.77594 115.275 3.4362C115.161 3.09646 115.059 2.7876 114.966 2.50963C114.873 2.22137 114.811 2.03605 114.781 1.95369L115.136 1.93825C115.074 2.11327 114.997 2.33461 114.904 2.60229C114.822 2.86996 114.729 3.15308 114.626 3.45164C114.533 3.7502 114.441 4.03332 114.348 4.30099C114.255 4.55837 114.178 4.77457 114.117 4.94959L111.661 11.4047H109.962ZM111.816 8.71763L112.433 7.11158H117.344L118.054 8.71763H111.816Z" fill="#1E1E1E"/>
            <path d="M102.215 11.559C101.463 11.559 100.773 11.4251 100.145 11.1575C99.5172 10.8898 98.9715 10.5089 98.5083 10.0147C98.045 9.51024 97.6846 8.91826 97.4273 8.23878C97.1802 7.5593 97.0566 6.8129 97.0566 5.99958C97.0566 5.22744 97.1905 4.51193 97.4582 3.85304C97.7361 3.18385 98.117 2.59702 98.6009 2.09256C99.0848 1.58809 99.6459 1.19688 100.284 0.918908C100.922 0.640938 101.612 0.501953 102.354 0.501953C102.879 0.501953 103.388 0.584314 103.882 0.749037C104.387 0.903465 104.84 1.12481 105.241 1.41308C105.653 1.69105 105.988 2.0102 106.245 2.37053L105.133 3.52874C104.835 3.23018 104.531 2.98309 104.222 2.78748C103.924 2.58158 103.615 2.42715 103.296 2.3242C102.987 2.22125 102.673 2.16977 102.354 2.16977C101.849 2.16977 101.375 2.26758 100.933 2.46318C100.5 2.6485 100.119 2.91103 99.79 3.25077C99.4709 3.59051 99.2186 3.99717 99.0333 4.47075C98.848 4.93403 98.7553 5.44364 98.7553 5.99958C98.7553 6.58641 98.8429 7.12176 99.0179 7.60563C99.2032 8.07921 99.4606 8.48587 99.79 8.82561C100.119 9.16535 100.511 9.42788 100.964 9.61319C101.427 9.78821 101.937 9.87572 102.492 9.87572C102.832 9.87572 103.167 9.82939 103.496 9.73673C103.826 9.64408 104.135 9.51024 104.423 9.33522C104.711 9.1602 104.974 8.9543 105.21 8.71751L106.044 10.0765C105.808 10.3544 105.483 10.6067 105.071 10.8332C104.67 11.0597 104.217 11.2398 103.712 11.3737C103.218 11.4972 102.719 11.559 102.215 11.559Z" fill="#1E1E1E"/>
            <path d="M87.499 11.4047V9.86039H89.2441V2.139H87.499V0.594727H92.6569V2.139H90.9119V9.86039H92.6569V11.4047H87.499Z" fill="#1E1E1E"/>
            <path d="M72.8755 11.4047V0.594727H74.3734L80.9829 9.44344L80.7359 9.58242C80.6947 9.32504 80.6586 9.04707 80.6278 8.74851C80.5969 8.43966 80.566 8.11536 80.5351 7.77562C80.5145 7.42558 80.4939 7.0601 80.4733 6.67918C80.463 6.28796 80.4527 5.8813 80.4424 5.4592C80.4322 5.0371 80.427 4.59955 80.427 4.14657V0.594727H82.0948V11.4047H80.566L74.0646 2.88026L74.2345 2.66406C74.2859 3.29207 74.3271 3.82741 74.358 4.27011C74.3992 4.70251 74.4301 5.07313 74.4507 5.38199C74.4815 5.68055 74.5021 5.92763 74.5124 6.12324C74.5227 6.30855 74.5279 6.46298 74.5279 6.58652C74.5382 6.71007 74.5433 6.81817 74.5433 6.91082V11.4047H72.8755Z" fill="#1E1E1E"/>
            <path d="M62.5405 11.5592C61.7787 11.5592 61.0735 11.4202 60.4249 11.1423C59.7866 10.8643 59.2255 10.4782 58.7416 9.98407C58.268 9.4796 57.8974 8.89277 57.6297 8.22359C57.3723 7.54411 57.2437 6.80285 57.2437 5.99983C57.2437 5.1968 57.3723 4.4607 57.6297 3.79151C57.8974 3.11203 58.268 2.5252 58.7416 2.03104C59.2255 1.52657 59.7866 1.13535 60.4249 0.857385C61.0735 0.579415 61.7787 0.44043 62.5405 0.44043C63.3024 0.44043 64.0024 0.579415 64.6407 0.857385C65.2893 1.13535 65.8504 1.52657 66.324 2.03104C66.8079 2.5355 67.1785 3.12747 67.4359 3.80696C67.7036 4.47614 67.8374 5.2071 67.8374 5.99983C67.8374 6.79256 67.7036 7.52866 67.4359 8.20815C67.1785 8.87733 66.8079 9.46416 66.324 9.96862C65.8504 10.4731 65.2893 10.8643 64.6407 11.1423C64.0024 11.4202 63.3024 11.5592 62.5405 11.5592ZM62.5405 9.93774C63.0656 9.93774 63.5443 9.83993 63.9767 9.64432C64.4194 9.44872 64.8003 9.17589 65.1195 8.82586C65.4386 8.46553 65.6857 8.04857 65.8607 7.57499C66.046 7.09112 66.1387 6.56606 66.1387 5.99983C66.1387 5.43359 66.046 4.91369 65.8607 4.44011C65.6857 3.95624 65.4386 3.53928 65.1195 3.18924C64.8003 2.82891 64.4194 2.55094 63.9767 2.35533C63.5443 2.15973 63.0656 2.06192 62.5405 2.06192C62.0155 2.06192 61.5316 2.15973 61.0889 2.35533C60.6565 2.55094 60.2756 2.82376 59.9461 3.1738C59.627 3.52384 59.3799 3.94079 59.2049 4.42467C59.0299 4.89824 58.9424 5.4233 58.9424 5.99983C58.9424 6.56606 59.0299 7.09112 59.2049 7.57499C59.3799 8.05887 59.627 8.47582 59.9461 8.82586C60.2756 9.17589 60.6565 9.44872 61.0889 9.64432C61.5316 9.83993 62.0155 9.93774 62.5405 9.93774Z" fill="#1E1E1E"/>
            <path d="M41.8896 11.4047V0.594727H43.5266L47.4645 7.00348L46.6769 6.98804L50.6611 0.594727H52.2054V11.4047H50.5376V6.97259C50.5376 6.04603 50.5582 5.21212 50.5994 4.47086C50.6508 3.72961 50.7332 2.9935 50.8465 2.26255L51.0627 2.83393L47.5263 8.30067H46.507L43.0787 2.8957L43.2486 2.26255C43.3619 2.95232 43.4391 3.66269 43.4803 4.39365C43.5317 5.11431 43.5575 5.97396 43.5575 6.97259V11.4047H41.8896Z" fill="#1E1E1E"/>
            <path d="M28.3726 11.4047V0.594727H32.9745C33.5922 0.594727 34.1585 0.749155 34.6732 1.05801C35.1983 1.35657 35.6152 1.76838 35.9241 2.29343C36.2329 2.80819 36.3874 3.38987 36.3874 4.03847C36.3874 4.62529 36.2329 5.17094 35.9241 5.6754C35.6152 6.17986 35.2034 6.58652 34.6887 6.89538C34.1739 7.19394 33.6025 7.34322 32.9745 7.34322H30.0404V11.4047H28.3726ZM34.7968 11.4047L32.0479 6.52475L33.7621 6.13868L36.8198 11.4201L34.7968 11.4047ZM30.0404 5.73717H33.1289C33.4275 5.73717 33.6952 5.6651 33.932 5.52097C34.1687 5.36654 34.3541 5.16064 34.4879 4.90326C34.6217 4.63559 34.6887 4.34217 34.6887 4.02302C34.6887 3.66269 34.6011 3.34869 34.4261 3.08101C34.2614 2.80304 34.0246 2.58684 33.7158 2.43242C33.4172 2.27799 33.0775 2.20078 32.6965 2.20078H30.0404V5.73717Z" fill="#1E1E1E"/>
            <path d="M13.6523 11.4047L17.8373 0.594727H19.536L23.6901 11.4047H21.9142L19.4434 4.88782C19.4125 4.81575 19.3507 4.64073 19.2581 4.36276C19.1757 4.08479 19.0779 3.77594 18.9647 3.4362C18.8514 3.09646 18.7485 2.7876 18.6558 2.50963C18.5631 2.22137 18.5014 2.03605 18.4705 1.95369L18.8257 1.93825C18.7639 2.11327 18.6867 2.33461 18.594 2.60229C18.5117 2.86996 18.419 3.15308 18.3161 3.45164C18.2234 3.7502 18.1307 4.03332 18.0381 4.30099C17.9454 4.55837 17.8682 4.77457 17.8065 4.94959L15.351 11.4047H13.6523ZM15.5055 8.71763L16.1232 7.11158H21.034L21.7444 8.71763H15.5055Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.61112 0.506348H0V5.10277H8.71898V0.506348H7.10787V3.49165H1.61112V0.506348ZM1.61112 11.4047H0V6.80829H8.71898V11.4047H7.10787V8.4194H1.61112V11.4047Z" fill="#1E1E1E"/>
        </svg>
    )
}

interface IProps {
    children: React.ReactNode;
    title?: string;
}

const BaseLayout = ({children, title='Автоматична оцінка'}:IProps) => {

    return (
        <div className={styles.layout}>
            <Sidebar />
            <div className={styles.layout__container}>
                <div className={styles.layout__header}>
                    <h1 className={styles.layout__title}>
                        {title}
                    </h1>
                    <TitleIcon />
                    <div className={styles.layout__info}>
                        <CompanyInfo/>
                        <UserInfo />
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};

export default BaseLayout;
