import React, {useMemo, useState} from 'react';
import useOutsideClick from "../../../../hooks/useOutsideClick";
import {useSearchParams} from "react-router-dom";
import {EnumChecklistTypes} from "../../index";
import StringService from "../../../../services/StringService";
import styles from './styles.module.scss';

const ArrowDownIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M13.0176 7.5542L8.99972 11.5721L4.98186 7.5542" stroke="black" strokeOpacity="0.4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

const DeleteIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.2803 4.71967C19.5732 5.01256 19.5732 5.48744 19.2803 5.78033L5.78033 19.2803C5.48744 19.5732 5.01256 19.5732 4.71967 19.2803C4.42678 18.9874 4.42678 18.5126 4.71967 18.2197L18.2197 4.71967C18.5126 4.42678 18.9874 4.42678 19.2803 4.71967Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.71967 4.71967C5.01256 4.42678 5.48744 4.42678 5.78033 4.71967L19.2803 18.2197C19.5732 18.5126 19.5732 18.9874 19.2803 19.2803C18.9874 19.5732 18.5126 19.5732 18.2197 19.2803L4.71967 5.78033C4.42678 5.48744 4.42678 5.01256 4.71967 4.71967Z" fill="#1E1E1E"/>
        </svg>
    )
}

interface IProps {
    title: string;
    shortTitle: string;
    columnName: string;
}

const HeadFilterYesNo = ({title, columnName, shortTitle}:IProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showTooltip, setShowTooltip] = useState(false);

    const value = useMemo(() => {
        return searchParams.get(EnumChecklistTypes.yesNo);
    },[searchParams])

    const handleToggleOpen = () => setIsOpen((prev) => !prev);
    const handleClose = () => setIsOpen(false);

    const ref = useOutsideClick(handleClose);

    const handleSelectValue = (e:React.MouseEvent<HTMLButtonElement>) => {
        const value = e.currentTarget.id;

        setSearchParams((prev) => {
            if(value === '_'){
                prev.delete(EnumChecklistTypes.yesNo);
                return prev;
            }
            prev.set(EnumChecklistTypes.yesNo, value);
            return prev;
        });
        handleClose();
    };

    const isActiveFilter = (yesNoValue:string) => {
        if(!value) return;
        const column = value.split('_')[0];
        const yesNo = value.split('_')[1];

        return column === columnName && yesNo === yesNoValue;
    }

    const isActiveTitle = () => {
        if(!value) return;
        const column = value.split('_')[0];
        return column === columnName;
    };

    const handleMouseEnter = () => setShowTooltip(true);
    const handleMouseLeave = () => setShowTooltip(false);

    return (
        <div
            className={styles.filter}
            ref={ref}
        >
            {showTooltip ? (
                <div className={styles.filter__tooltip}>
                    <span>
                        {shortTitle}
                    </span>
                    {title}
                </div>
            ) : null}
            <button
                className={`${styles.filter__head} ${isActiveTitle() ? styles.filter__head_active : ''}`}
                type={'button'}
                onClick={handleToggleOpen}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className={styles.filter__title}>
                    <span>
                        {StringService.cutString(shortTitle, 7)}
                    </span>
                    <div className={styles.filter__icon}>
                        <ArrowDownIcon />
                    </div>
                </div>
            </button>
            {isOpen ? (
                <div className={styles.filter__list}>
                    <button
                        className={`${styles.filter__item} ${isActiveFilter('1') ? styles.filter__item_active : ''}`}
                        type={'button'}
                        id={`${columnName}_1`}
                        onClick={handleSelectValue}
                    >
                        <span>Так</span>
                    </button>
                    <button
                        className={`${styles.filter__item} ${isActiveFilter('0') ? styles.filter__item_active : ''}`}
                        type={'button'}
                        onClick={handleSelectValue}
                        id={`${columnName}_0`}
                    >
                        <span>Ні</span>
                    </button>
                    {1 ? (
                        <button
                            className={styles.filter__item}
                            type={'button'}
                            id={`_`}
                            onClick={handleSelectValue}
                        >
                            <DeleteIcon />
                            <span>Не фільтрувати</span>
                        </button>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default HeadFilterYesNo;
